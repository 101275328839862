import React from 'react'
import { Badge } from 'react-bootstrap'

export const Status = {
  open: 'open'
}

export const statusSortOrder = {
  draft: 1,
  open: 2,
  review: 3,
  post_sale: 4,
  closed: 5
}

export const statusStyleMapping = {
  open: 'success',
  draft: 'dark',
  review: 'warning',
  closed: 'info',
  post_sale: 'danger'
}

export const statusLabelMapping = {
  open: 'Open',
  draft: 'Draft',
  review: 'Review',
  closed: 'Closed',
  post_sale: 'Post Sale'
}

export const ListingStatus = ({ status, ...props }) => {
  return <Badge pill variant={statusStyleMapping[status]} className="ml-3" {...props}>{statusLabelMapping[status]}</Badge>
}

export default ListingStatus
